import React from 'react';
import ContentPage, {Frame, Section, H1, H2, P, Link} from './../ContentPage';
import { REACT_APP_CONTACT_EMAIL } from '../../../../config';

export default () => (
  <ContentPage>
          <H1>NerdyChat Honor Code</H1>
          <Frame>NerdyChat is a mobile application designed to help students enhance their study skills and knowledge. We are committed to promoting academic integrity and ethical conduct among our users. As a user of NerdyChat, you are expected to abide by the following honor code:</Frame>

    <Section>
      <H2>Cheating and Academic Dishonesty</H2>
      <P>NerdyChat does not condone or facilitate academic dishonesty, cheating, or any other violation of academic policies. You are not allowed to use the app to request or provide unauthorized outside help on any academic assignment or assessment.</P>
    </Section>

    <Section>
      <H2>Plagiarism</H2>
      <P>Using the work of another person without proper attribution is strictly prohibited on NerdyChat. You are not allowed to copy or submit any content generated by the app as your own work.</P>
    </Section>

    <Section>
      <H2>Identity Theft</H2>
      <P>Assuming someone else's identity or sharing your login credentials with others is not allowed on NerdyChat. You are responsible for maintaining the confidentiality of your account information.</P>
    </Section>

    <Section>
      <H2>Legal Compliance</H2>
      <P>You are not allowed to use NerdyChat for any illegal activities. You are responsible for complying with all applicable laws and regulations.</P>
    </Section>

    <Section>
      <H2>Prohibited Tasks</H2>
      <P>You are not allowed to use NerdyChat for any tasks that violate the academic or ethical policies of your institution. Prohibited tasks include, but are not limited to, completing graded assignments, taking tests or exams, and fabricating research data.</P>
    </Section>

    <Section>
      <H2>Consequences of Violation</H2>
      <P>Any violation of the NerdyChat honor code will be subject to investigation, and appropriate actions will be taken to ensure compliance. This may include the suspension or termination of your account and other legal actions if necessary.</P>
    </Section>

    <Section>
      <H2>Contact Information Sharing</H2>
      <P>You are not allowed to share any personal contact information with other users on NerdyChat. Please use the app's communication features to interact with other users.</P>
    </Section>

    <Section>
      <H2>Reporting Abuse</H2>
      <P>If you become aware of any violation of the NerdyChat honor code, please report it immediately to our support team via <Link external to={`mailto:${REACT_APP_CONTACT_EMAIL}`}>{REACT_APP_CONTACT_EMAIL}</Link>. We take all reports of abuse seriously and investigate each one thoroughly.</P>
      <P>By using NerdyChat, you acknowledge that you have read and agree to abide by this honor code.</P>
    </Section>
  </ContentPage>
)
