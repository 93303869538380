import React from 'react';
import Slider from 'react-slick';

import classnames from 'classnames';
import {createUseStyles} from 'react-jss';
import {mobile} from '../../assets/styles';
import { useMobile } from '../../../../hooks';

import './slickSlider.scss';
import './slickSliderCustom.css';

const useStyle = createUseStyles({
  item: {
  },
  itemImg: {
    [mobile]: {
      width: 299,
    }
  }
});

const SliderDesktop = () => {
  const classes = useStyle();
  return (
    <Slider
      className={'main-slider'}
      useTransform={false}
      infinite={true}
      fade={true}
      //adaptiveHeight={true}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={true}
      autoplaySpeed={7000}
      swipe={true}
      arrows={false}
      dots={true}
      customPaging={() => {
        return (
          <button className="slick-dots-btn" />
        )
      }}
    >
      <div className={classnames(classes.item, classes.item1)}>
        <img src={require('./img/frame1.svg')} alt=""/>
      </div>
      <div className={classnames(classes.item, classes.item3)}>
        <img src={require('./img/frame2.svg')} alt=""/>
      </div>
      <div className={classnames(classes.item, classes.item2)}>
        <img src={require('./img/frame3.svg')} alt=""/>
      </div>
    </Slider>
  )
};

const SliderMobile = () => {
  const classes = useStyle();
  return (
    <Slider
      variableWidth
      useTransform={false}
      //centerMode
      infinite={false}
      slidesToShow={1}
      className={'main-slider'}
      //adaptiveHeight={true}
      slidesToScroll={1}
      swipe={true}
      arrows={false}
      dots={true}
      customPaging={() => {
        return (
          <button className="slick-dots-btn" />
        )
      }}
    >
      <div className={classnames(classes.item, classes.item1)}>
        <img className={classes.itemImg} src={require('./img/frame1-mob.svg')} alt=""/>
      </div>
      <div className={classnames(classes.item, classes.item2)}>
        <img className={classes.itemImg} src={require('./img/frame2-mob.svg')} alt=""/>
      </div>
      <div className={classnames(classes.item, classes.item3)}>
        <img className={classes.itemImg} src={require('./img/frame3-mob.svg')} alt=""/>
      </div>
    </Slider>
  )
};

const TipsSlider = () => {
  const isMobile = useMobile();
  return (
    <div className={classnames('slider')}>
      {isMobile ? SliderMobile()
        : SliderDesktop()}
    </div>
  )
};

export default TipsSlider;
