import Landing from './pages/Landing';
import HonorCode from './pages/HonorCode';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

import NotFound from './pages/NotFound';
//import { IS_PROD } from '../../config';

import './assets/css/index.css';
export const viewport = 'width=device-width';

export const routes = [
  {
    path: '/',
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'index, follow',
    },
    component: Landing,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/honor-code',
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'index, follow',
    },
    component: HonorCode,
    pageCfg: {},
  },
  {
    path: '/privacy-policy',
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'index, follow',
    },
    component: Privacy,
    pageCfg: {},
  },
  {
    path: '/terms-of-service',
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'index, follow',
    },
    component: Terms,
    pageCfg: {},
  },
  {
    meta: {
      title: 'Error',
      description: 'Error page',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: NotFound,
    pageCfg: {},
  },
];
