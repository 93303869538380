import React from 'react';
import ContentPage, {Frame, Section, H1, H2, P, Ul, Li, Link} from './../ContentPage';
import { REACT_APP_CONTACT_EMAIL } from '../../../../config';

export default () => (
  <ContentPage>
          <H1>PRIVACY POLICY</H1>
          <Frame>This Privacy Policy ("Policy") applies to the "NerdyChat" Application ("Application", "we", "us", or "our") and sets out how we collect, use, disclose, and safeguard your information.</Frame>

    <Section>
      <H2>1. Introduction</H2>
      <P>Your privacy is important to us. We encourage you to read this Policy carefully, and to use the information it contains to help you make informed decisions about your personal data.</P>
    </Section>

    <Section>
      <H2>2. Personal Data Collection and Use</H2>
      <P>We collect data to provide a better service to all our users. We collect information in the following ways:</P>
      <Ul>
        <Li><strong>Information you give us</strong>: When you use our Application, we may ask for personal information, like your name, email address, and educational level.</Li>
        <Li><strong>Information we get from your use of our services</strong>: We may collect information about the services that you use and how you use them. This information includes:</Li>
        <Li><strong>Device information</strong></Li>
        <Li><strong>Log information</strong></Li>
        <Li><strong>Location information</strong></Li>
        <Li><strong>Local storage</strong></Li>
      </Ul>
      <P>We use the information we collect from all our services to maintain, protect and improve them, to develop new ones, and to protect us and our users.</P>

      <P>The following list presents different kinds of cookies that our Services use and explains why we use them.</P>
      <Ul>
        <Li><strong>Essentials</strong>. Provide functionality that help us deliver products and Services. For example, cookies may store login information that helps users enter the website easily. We may also use cookies and similar technologies to help us provide you with social plugins, other customized content and experiences, such as making suggestions to you and others.</Li>
        <Li><strong>Marketing</strong>. Cookies and pixels are used to provide relevant ads, track ad campaign performance and the effectiveness of our marketing efforts. For example, we and our ad partners may rely on information collected via these cookies to show you ads that may interest you on other websites. Similarly, our partners may use cookies, attribution service or another similar technology to determine whether we’ve served an ad and how it performed.</Li>
        <Li><strong>Performance</strong>. Help research, understand and improve products and Services. For example, when you access the Nerdify website or other related websites and apps from a computer or a mobile device. We may use cookies to understand your user experience on our platform. The collected data are used to improve and understand how you use websites, apps, products, services and ads.</Li>
        <Li><strong>Functional</strong>. These cookies and similar technologies remember choices you make such as language or search parameters. We use these cookies to provide you with an experience more appropriate with your selections and to make your use of the Services more tailored.</Li>
      </Ul>
    </Section>

    <Section>
      <H2>3. Data Sharing</H2>
      <P>We do not share personal information with companies, organizations, and individuals outside of the Application unless one of the following circumstances applies:</P>
      <Ul>
        <Li><strong>With your consent</strong>: We will only share personal information with companies, organizations, or individuals outside of the Application when we have your consent to do so.</Li>
        <Li><strong>For legal reasons</strong>: We will share personal information with companies, organizations, or individuals outside of the Application if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to meet any applicable law, regulation, legal process, or enforceable governmental request.</Li>
      </Ul>
    </Section>

    <Section>
      <H2>4. Data Security</H2>
      <P>We work hard to protect our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold. We implement various security measures including encryption of the Application and review our information collection, storage, and processing practices, including physical security measures, to guard against unauthorized access to systems.</P>
    </Section>

    <Section>
      <H2>5. Third-Party Services</H2>
      <P>The Application may contain links to third-parties websites, products, and services. Information collected by third parties is governed by their privacy practices. We encourage you to learn about the privacy practices of those third parties.</P>
    </Section>

    <Section>
      <H2>6. Changes to this Privacy Policy</H2>
      <P>Our Privacy Policy may change from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice.</P>
    </Section>

    <Section>
      <H2>7. Contact Us</H2>
      <P>If you have any questions regarding our Privacy Policy, you can contact us via <Link external to={`mailto:${REACT_APP_CONTACT_EMAIL}`}>email</Link>.</P>
    </Section>

    <Section>
      <H2>8. Acknowledgment to Apple</H2>
      <P>You acknowledge that this Privacy Policy is provided to, and intended to be used by, any person who is a user of the Application or the Apple App Store (the "User"), and the User acknowledges as a condition of using the Application that no Apple entity is responsible for the Application's Content segment, which includes user-generated content (if any), and Apple is not responsible for any claims made in relation to the Content.</P>
      <P>Last Updated: May 12, 2023</P>
    </Section>

  </ContentPage>
)
