import React from 'react';

import { Link } from 'react-router-dom';
//import classnames from 'classnames';
import Holder from '../Holder'
import {createUseStyles} from 'react-jss';
import icons from '../Icons/icons.svg'
import Icon from '../Icons';
//import {media, mobile, desktop} from '../../assets/styles';

const useStyle = createUseStyles({
  header: {
    borderBottom: '1px solid #E8EEF2',
  },
  holder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },
  icon: {
    display: 'block',
  },
});

const Header = () => {
  const classes = useStyle();
  return (
    <header className={classes.header}>
      <Holder type={1} className={classes.holder}>
        <Link to="/">
          <Icon className={classes.icon} icons={icons} iconName="logo_black" width={97} height={60}/>
        </Link>
      </Holder>
    </header>
  );
};

export default Header;
