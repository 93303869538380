import React from 'react';

import Holder from '../../blocks/Holder';
import Hero from '../../blocks/Hero';
import WhatCanDo from '../../blocks/WhatCanDo';
import ProTips from '../../blocks/ProTips';
import Footer from '../../blocks/Footer';
import { createUseStyles } from 'react-jss';
import { useMobile } from '../../../../hooks';
import {mobile, desktop} from '../../assets/styles';

const howList = [
  {
   icon: 'Icon_1.svg',
   title: 'Download NerdyChat App from App Store'
  },
  {
   icon: 'Icon_2.svg',
   title: 'Text your question or just send a pic of it'
  },
  {
   icon: 'Icon_3.svg',
   title: 'Get instant answers & step-by-step guidance.'
  },
  {
   icon: 'Icon_4.svg',
   title: 'Chat with AI like you would with a smart friend.'
  },
];

const useStyle = createUseStyles({
  howHolder: {
    [mobile]: {
      padding: [50, 20],
    },
    [desktop]: {
      padding: [50, 0, 56],
    }
  },
  howTitle: {
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '57px',
    textAlign: 'center',
    color: '#000',
  },
  howList: {
    marginTop: 32,
    [mobile]: {
      padding: [0, 32],
    },
    [desktop]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: 60,
    },
  },
  howItem: {
    display: 'flex',
    gap: 25,
    [mobile]: {
      '&:not(:first-child)': {
        marginTop: 32,
      }
    },
    [desktop]: {
      flexDirection: 'column',
      width: 212,
      gap: 25,
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'center',
    },
  },
  itemIcon: {
  },
  itemTitle: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: '#000',
  },
});


export default () => {
  const classes = useStyle();
  //const isMobile = useMobile();
  return (
    <>
      <main className="main">
        <Hero />
        <div className={classes.howBlock}>
          <Holder className={classes.howHolder}>
            <div className={classes.howTitle}>How to use NerdyChat</div>
            <ul className={classes.howList}>
              {howList.map((item, key) => {
                return(
                  <li className={classes.howItem} key={`item_${key}`}>
                    <img className={classes.itemIcon}  src={require(`./img/${item.icon}`)} width="32" height="32" alt=""/>
                    <div className={classes.itemTitle}>{item.title}</div>
                  </li>
                )
              })}
            </ul>
          </Holder>
        </div>
        <WhatCanDo />
        <ProTips/>
      </main>
      <Footer />
    </>
  )
}
