import React from 'react';
import {createUseStyles} from 'react-jss';
import {mobile, desktop} from '../../assets/styles';
import { useMobile } from '../../../../hooks';
import classnames from 'classnames';

const useStyle = createUseStyles({
  appBlock: {
    [desktop]: {
      display: 'flex',
      marginLeft: -12,
    }
  },
  appstoreImg: {
    [mobile]: {
      height: 80,
      width: 203,
    },
  },
  qrcodeImg: {
    marginLeft: -4,
  },
});

const AppBlock = ({ className }) => {
  const classes = useStyle();
  const isMobile = useMobile();
  return (
    <div className={classnames(classes.appBlock, className )}>
      <a href="#">
        <img className={classes.appstoreImg} src={require('./img/appstore_button@2x.png')} width="238" height="95" alt=""/>
      </a>
      {!isMobile && <img className={classes.qrcodeImg} src={require('./img/qrcode.svg')} width="95" height="95" alt=""/>}
    </div>
  );
};

export default AppBlock;
