import React from 'react';
import classnames from 'classnames/index';

import { Link } from 'react-router-dom';
import {mobile, desktop} from '../../assets/styles';
import Header from '../../blocks/Header';
import Footer from '../../blocks/Footer';
import Holder from '../../blocks/Holder';
import {createUseStyles} from 'react-jss'

const useStyle = createUseStyles({
  holder: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#000',
    [mobile]: {
      padding: [44,20,50]
    },
    [desktop]: {
      padding: [44,106,60],
    }
  },
  title1: {
    fontSize: 24,
    fontWeight: 700,
  },
  title2: {
    fontSize: 18,
    fontWeight: 700,
  },
  frame: {
    margin: [20,0,40]
  },
  section: {
    marginTop: 32,
  },
  p: {
    marginTop: 8,
  },
  link: {
    textDecoration: 'underline',
  },
  ul: {
    listStylePosition: 'inside',
    marginTop: 8,
  },
  li: {
    '&:before': {
      content: '"•"',
      margin: [0, 8],
    }
  }
});


export default ({ className, children }) => {
  //const isMobile = useMobile();
  const classes = useStyle();
  return (
    <>
      <Header />
      <main className="main">
        <Holder className={classes.holder}>
          {children}
        </Holder>
      </main>
      <Footer />
    </>
  )
};

const Frame = ({ className, children }) => {
  const classes = useStyle();
  return (
    <p className={classnames(classes.frame, className)}>
      {children}
    </p>
  )
};

const Section = ({ className, children }) => {
  const classes = useStyle();
  return (
    <section className={classnames(classes.section, className)}>
      {children}
    </section>
  )
};

const H1 = ({ className, children }) => {
  const classes = useStyle();
  return (
    <h1 className={classnames(classes.title1, className)}>
      {children}
    </h1>
  )
};

const H2 = ({ className, children }) => {
  const classes = useStyle();
  return (
    <h2 className={classnames(classes.title2, className)}>
      {children}
    </h2>
  )
};

const P = ({ className, children }) => {
  const classes = useStyle();
  return (
    <p className={classnames(classes.p, className)}>
      {children}
    </p>
  )
};

const LinkCustom = ({ className, children, external, to }) => {
  const classes = useStyle();
  if (external) return (
    <a className={classnames(classes.link, className)} href={to}>
      {children}
    </a>
  );
  else return (
    <Link className={classnames(classes.link, className)} to={to}>
      {children}
    </Link>
  )
};

const Ul = ({ className, children }) => {
  const classes = useStyle();
  return (
    <ul className={classnames(classes.ul, className)}>
      {children}
    </ul>
  )
};

const Li = ({ className, children }) => {
  const classes = useStyle();
  return (
    <li className={classnames(classes.li, className)}>
      {children}
    </li>
  )
};


export {Frame, Section, H1, H2, P, LinkCustom as Link, Ul, Li}
