import React, { useState, useEffect, useRef } from 'react';

//import classnames from 'classnames';
import Holder from '../Holder';
import AppBlock from '../AppBlock';
import {createUseStyles} from 'react-jss';
import {mobile, desktop} from '../../assets/styles';
//import { useMobile } from '../../../../hooks';

import subjects from './subjects.json';
import classnames from 'classnames';

/*
const shuffleArray = (array) => {
  const result = [...array];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
};
*/

const useStyle = createUseStyles({
  whatCanDo: {
  },
  holder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    background: '#F5F8FA',
    borderRadius: 16,
    position: 'relative',
    [mobile]: {
      width: '100%',
      padding: [40,20],
    },
    [desktop]: {
      padding: [40,0],
    }
  },
  title: {
    fontSize: 40,
    lineHeight: '57px',
    textAlign: 'center',
    color: '#000',
    marginTop: 32,
  },
  subtitle: {
    fontSize: 18,
    lineHeight: '32px',
    textAlign: 'center',
    color: 'rgba(0,0,0,.8)',
    marginTop: 16,
  },

  '@global': {
    '@keyframes falsum': {
      '0%': {
        transform: 'translate3d(0, 0, 0)',
      },
      '100%': {
        transform: 'translate3d(-50%, 0, 0)',
      },
    },
  },

  demoSubjects: {
    [mobile]: {
      margin: [40, -20, 0],
      width: 'calc(100% + 40px)'
    },
    [desktop]: {
      width: '100%',
      marginTop: 40,
    },
  },
  demoRow: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    marginBottom: 16,
    animationName: 'falsum',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    animationDuration: '0ms',
    transform: 'translate3d(0,0,0)',
  },
  demoItem: {
    display: 'inline-block',
    fontSize: 16,
    lineHeight: '22px',
    padding: [10, 16],
    backgroundColor: '#fff',
    color: '#1745E8',
    borderRadius: 100,
    marginRight: 16,
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.05)',
  },
  demoSubjects__list: {
    overflow: 'hidden',
    visibility: 'hidden',
  },
  demoSubjects__list_mounted: {
    visibility: 'unset',
  },
  appBlock: {
    marginTop: 40,
  },
  bottomLine: {
    marginTop: 5,
    fontSize: 18,
    lineHeight: '24px',
    color: 'rgba(0,0,0,.8)',
  },
});

const SPEED = 75;

const Row = ({ items, mobile, desktop }) => {
  const [duration, setDuration] = useState(0);
  const [mounted, setMounted] = useState(false);
  const rowRef = useRef(null);
  const classes = useStyle();

  useEffect(() => {
    const length = rowRef.current.offsetWidth;
    const duration = length / SPEED;
    setDuration(duration);
    setMounted(true);
  }, []);

  const className = classnames(classes.demoRow, { mounted }, { mobile }, { desktop });
  const style = !duration ? {} : { animationDuration: `${duration}s` };

  return (
    <div className={className} ref={rowRef} style={style}>
      {items.map((item) => <div key={item} className={classes.demoItem}>{item}</div>)}
      {items.map((item) => <div key={item} className={classes.demoItem}>{item}</div>)}
    </div>
  );
};

const DemoSubjects = () => {
  const classes = useStyle();
  const rows = [
    subjects.row1,
    subjects.row2,
  ];
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className={classes.demoSubjects}>
      <div
        className={classnames(classes.demoSubjects__list, {
          [classes.demoSubjects__list_mounted]: mounted,
        })}
      >
        {rows.map((row, idx) => (
          <Row items={row} key={idx} />
        ))}
      </div>
    </div>
  );
};

const WhatCanDo = () => {
  const classes = useStyle();
  //const isMobile = useMobile();
  return (
    <div className={classes.whatCanDo}>
      <Holder className={classes.holder}>
        <img className={classes.logo} src={require('./img/logo.svg')} width="64" height="30" alt=""/>
        <div className={classes.title}>What can <span role="img" aria-label="bot">🤖</span> NerdyChat do?</div>
        <div className={classes.subtitle}>NerdyChat is powered by ChatGPT and can perform virtually any task.<br/> We've customised it to be a better help for students with:</div>
        <DemoSubjects />
        <AppBlock className={classes.appBlock} />
        <div className={classes.bottomLine}>Ask. Solve. Learn</div>
      </Holder>
    </div>
  );
};

export default WhatCanDo;
