import React from 'react';
import {createUseStyles} from 'react-jss';
import {mobile, desktop} from '../../assets/styles';

import { Link } from 'react-router-dom';

import { useMobile } from '../../../../hooks';
import icons from '../Icons/icons.svg'
import Icon from '../Icons';
import Holder from '../Holder'

import {
  REACT_APP_CONTACT_EMAIL,
} from './../../../../config';

const useStyle = createUseStyles({
  holder: {
    display: 'flex',
    alignItems: 'center',
    [mobile]: {
      paddingTop: 24,
      paddingBottom: 24,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    [desktop]: {
      paddingTop: 20,
      paddingBottom: 40,
      justifyContent: 'flex-start',
    },
  },
  logo: {
    display: 'block',
  },
  navList: {
    display: 'flex',
    alignItems: 'center',
    [mobile]: {
      justifyContent: 'center',
      gap: 20,
      marginTop: 20,
    },
    [desktop]: {
      marginLeft: 'auto',
      justifyContent: 'flex-start',
      gap: 24,
    },
  },
  navListLink: {
    fontSize: 12,
    lineHeight: '16px',
  },
  contactLine: {
    fontSize: 12,
    lineHeight: '16px',
    [mobile]: {
      marginTop: 32,
      textAlign: 'center',
    },
    [desktop]: {
      marginLeft: 60,
    },
  },
  indicator: {
    width: 134,
    height: 5,
    background: '#00215C',
    borderRadius: 100,
    margin: '21px auto 8px'
  }
});

const Footer = ({type}) => {
  const classes = useStyle();
  const isMobile = useMobile();

  const FooterNavList = [
    { name: 'Terms and conditions', link: '/terms-of-service' },
    { name: 'Privacy policy', link: '/privacy-policy' },
    { name: 'Honor code', link: '/honor-code' },
  ];

  const FooterNav = () => {
    return (
      <nav className={classes.navList}>
        {FooterNavList.map((item, key) => {
          return(
            <Link className={classes.navListLink} to={item.link} key={`item_${key}`}>{item.name}</Link>
          )
        })}
      </nav>
    )
  };

  return (
    <footer className={classes.footer}>
      <Holder className={classes.holder}>
        <Icon className={classes.logo} icons={icons} iconName="logo_black" width={64} height={40}/>
        <FooterNav />
        <div className={classes.contactLine}>
          Contact us:{isMobile ? <br/> : ' '}
          <a className={classes.contactLink} href={"mailto:" + REACT_APP_CONTACT_EMAIL}>
            {REACT_APP_CONTACT_EMAIL}
          </a>
        </div>
      </Holder>
      {isMobile &&
      <Holder>
        <div className={classes.indicator} />
      </Holder>
      }
    </footer>
  )
};

export default Footer;
