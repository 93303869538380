import React from 'react';

//import classnames from 'classnames';
import Holder from '../Holder';
import TipsSlider from '../Slider';
import {createUseStyles} from 'react-jss';
import {mobile, desktop} from '../../assets/styles';
import { useMobile } from '../../../../hooks';

const useStyle = createUseStyles({
  whatCanDo: {
  },
  holder: {
    [mobile]: {
      padding: [60,0,35],
      textAlign: 'center',
      width: '100%',
    },
    [desktop]: {
      padding: [24,0],
      display: 'flex',
      gap: 8,
    }
  },
  title: {
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '28px',
    textAlign: 'center',
    color: '#000',
    marginBottom: 32,
  },
  videoWrapper: {
    width: 710,
    height: 355,
    borderRadius: 12,
    overflow: 'hidden',
    flexShrink: 0,
  },
  video: {
    width: '100%',
  },
});

const ProTips = () => {
  const classes = useStyle();
  const isMobile = useMobile();

  const title = <div className={classes.title}>Pro<span role="img" aria-label="idea">💡</span>Tips</div>;

  return (
    <div className={classes.whatCanDo}>
      <Holder className={classes.holder}>
        {isMobile && title}
        <TipsSlider />
        {!isMobile &&
        <div className={classes.videoWrapper}>
          <video className={classes.video} loop autoPlay muted>
            <source src="/video_sample.mp4" type="video/mp4" />
          </video>
        </div>
        }
      </Holder>
    </div>
  );
};

export default ProTips;
