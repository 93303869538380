import React from 'react';
import { NavLink } from 'react-router-dom'

import ContentPage from './../ContentPage';
import {REACT_APP_CONTACT_EMAIL} from "../../../../config";


export default () => (
  <ContentPage noWrap className={'not-found'}>
    <h1 className="title">Oops..</h1>
    <div className="subtitle">We can't find the page you're looking for, please text us via <a className="link" href={`mailto:${REACT_APP_CONTACT_EMAIL}`}>{REACT_APP_CONTACT_EMAIL}</a>.</div>
    <NavLink className="button" to='/'>Back to homepage</NavLink>
  </ContentPage>
)
