import React from 'react';
import ContentPage, {Frame, Section, H1, H2, P, Ul, Li, Link} from './../ContentPage';
//import { REACT_APP_CONTACT_EMAIL } from '../../../../config';

export default () => (
  <ContentPage>
          <H1>TERMS AND CONDITIONS</H1>
          <Frame>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS APPLICATION.</Frame>

          <Section>
                  <H2>1. Introduction</H2>
                  <P>This Application "NerdyChat" (hereinafter referred to as "Application") uses the API of ChatGPT, a language model developed by OpenAI, to help students learn and answer their study-related questions. This Application is not provided by Apple Inc., but by Go Smartly Tech LTD. ("us", "we", or "our").</P>
          </Section>

          <Section>
                  <H2>2. Acceptance of Terms</H2>
                  <P>By downloading or using the Application, you are agreeing to be bound by these terms and conditions ("Terms"), all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this Application.</P>
          </Section>

          <Section>
                  <H2>3. User License</H2>
                  <P>Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to use the Application for your personal, non-commercial use on an Apple-branded product that you own or control as permitted by the Usage Rules set forth in the Apple Media Services Terms and Conditions.</P>
          </Section>

          <Section>
                  <H2>4. Privacy</H2>
                  <P>Your privacy is important to us. Before you continue using our Application, we advise you to read our privacy policy regarding our user data collection, which you can find <Link to={'/privacy-policy'}>here</Link>. It will help you better understand our practices.</P>
          </Section>

          <Section>
                  <H2>5. Intellectual Property</H2>
                  <P>The Application and its original content, features and functionality, including text, graphics, logos, icons, images, audio clips, digital downloads, and software, are owned by us and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</P>
          </Section>

          <Section>
                  <H2>6. User Obligations</H2>
                  <P>As a user of the Application, you agree not to:</P>
                  <Ul>
                          <Li>Use the Application for any illegal purpose.</Li>
                          <Li>Use the Application to violate any policies of any organisation or institution you are a part of.</Li>
                          <Li>Use the Application to send spam or any other unsolicited communication.</Li>
                          <Li>Attempt to reverse engineer or compromise the Application's security or network.</Li>
                  </Ul>
          </Section>

          <Section>
                  <H2>7. Limitations</H2>
                  <P>In no event shall we be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the Application, even if we or a representative authorized by us has been notified orally or in writing of the possibility of such damage.</P>
          </Section>

          <Section>
                  <H2>8. Termination</H2>
                  <P>We may terminate your access to and use of the Application, at our sole discretion, at any time and without notice to you. Upon any termination, discontinuation, or cancellation of Services or your Account, all provisions of these Terms which by their nature should survive will survive, including, without limitation, ownership provisions, warranty disclaimers, limitations of liability, and dispute resolution provisions.</P>
          </Section>

          <Section>
                  <H2>9. Modifications</H2>
                  <P>We reserve the right to change, modify, add or remove portions of these Terms at any time. It is your responsibility to check these Terms periodically for changes. Your continued use of the Application following the posting of changes will mean that you accept and agree to the changes.</P>
          </Section>

          <Section>
                  <H2>10. Dispute Resolution and Governing Law</H2>
                  <P>These Terms shall be governed by and construed in accordance with the laws of the United States, without regard to its conflict of law provisions. Any dispute arising from these terms and conditions or your use of the Application will be resolved in the federal or state courts of the United States.</P>
          </Section>

          <Section>
                  <H2>11. Third Party Beneficiary</H2>
                  <P>You acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries of these Terms, and that, upon your acceptance of the terms and conditions of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary</P>
          </Section>

  </ContentPage>
)
