import { useState, useEffect } from 'react';

import { isClient } from '../utils';
import { trackValues, storageGetItem, storageSetItem } from '../lrtracker';

const useMobile = (width) => {
  const [isMobile, setIsMobile] = useState(false);
  const minWidth = width || 1240;

  useEffect(() => {
    const detectMobile = () => {
      setIsMobile(window.innerWidth <= minWidth);
    };

    window.addEventListener('resize', detectMobile);

    detectMobile();

    return () => {
      window.removeEventListener('resize', detectMobile);
    }
  }, [isMobile]);

  return isMobile;
};

const useTrackPrevLocation = () => {
  useEffect(() => {
    const prevLocation = storageGetItem('prev_location');
    let currLocation = isClient ? window.location.href : '';

    if (prevLocation !== currLocation) {
      storageSetItem('prev_location', currLocation);

      trackValues('stage', 'landing', {
        context: {
          moved_from: prevLocation,
        },
      });
    }
  }, []);
};

export { useMobile, useTrackPrevLocation };
