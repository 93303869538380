import React from 'react';

//import { Link } from 'react-router-dom';
//import classnames from 'classnames';
import Holder from '../Holder'
import AppBlock from '../AppBlock'
import {createUseStyles} from 'react-jss';
//import Icon from '../Icons';
import {mobile, desktop} from '../../assets/styles';
import { useMobile } from '../../../../hooks';

const useStyle = createUseStyles({
  hero: {
    [mobile]: {
      backgroundColor: '#5040ED',
      borderRadius: [0, 0, 16, 16],
      overflow: 'hidden',
    },
    [desktop]: {
      paddingTop: 40,
    }
  },
  holder: {
    display: 'flex',
    [mobile]: {
      flexDirection: 'column',
      minHeight: 896,
    },
    [desktop]: {
      gap: 8,
    }
  },
  section1: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [mobile]: {
      padding: [30, 20, 0],
      alignItems: 'center',
      textAlign: 'center',
    },
    [desktop]: {
      minHeight: 550,
      backgroundColor: '#5040ED',
      borderRadius: 16,
      padding: [31, 44],
    }
  },
  section2: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    overflow: 'hidden',
    [mobile]: {
      marginTop: 34,
    },
    [desktop]: {
      width: 521,
      backgroundColor: '#5040ED',
      borderRadius: 16,
    }
  },
  title: {
    fontWeight: 700,
    color: '#FFF',
    [mobile]: {
      marginTop: 32,
      fontSize: 40,
      lineHeight: '52px',
    },
    [desktop]: {
      marginTop: 44,
      fontSize: 44,
      lineHeight: '57px',
    },
  },
  subtitle: {
    marginTop: 16,
    fontSize: 20,
    lineHeight: '27px',
    letterSpacing: '0.01em',
    color: '#FFF',
  },
  appBlock: {
    marginTop: 'auto',
  },
  bottomLine: {
    fontSize: 18,
    color: '#FFF',
    [mobile]: {
      lineHeight: '24px',
    },
    [desktop]: {
      fontWeight: 700,
      lineHeight: '25px',
    }
  },
});


const Hero = () => {
  const classes = useStyle();
  const isMobile = useMobile();
  return (
    <div className={classes.hero}>
      <Holder type={1} className={classes.holder}>
        <div className={classes.section1}>
          <img className={classes.logo} src={require('./img/logo_new_white.svg')} width="106" height="67" alt=""/>
          <h1 className={classes.title}>Get Instant Answers.<br/>
            To All Study Questions.<br/>
            For free<span role="img" aria-label="clapping">👏</span></h1>
          <div className={classes.subtitle}>Powered by ChatGPT.{isMobile && <br/>} Made for students.</div>
          <AppBlock className={classes.appBlock} />
          <div className={classes.bottomLine}>Ask. Solve. Learn</div>
        </div>
        <div className={classes.section2}>
          {isMobile ? <img className={classes.logo} src="/img/phone_img_mob@2x.png" width="346" height="265" alt=""/>
          : <img className={classes.logo} src="/img/phone_img@2x.png" width="487" height="504" alt=""/>
          }
        </div>
      </Holder>
    </div>
  );
};

export default Hero;
