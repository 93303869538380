import React from 'react';

import classnames from 'classnames';
import {createUseStyles} from 'react-jss';
//import { useMobile } from '../../../../hooks';
import {media, mobile, desktop} from '../../assets/styles';

const useStyle = createUseStyles({
    holder: {
      marginLeft: 'auto',
      marginRight: 'auto',
      [mobile]: {
        width: media.mobile,
      },
      [desktop]: {
        width: media.desktop,
      },
    },
})

const Holder = ({ className, children }) => {
  const classes = useStyle();
  return (
    <div className={classnames(classes.holder, className )}>{children}</div>
  )
};

export default Holder;
