export const media = {
  mobile: 375,
  desktop: 1240,
};

export const mobile = `@media (max-width: ${media.desktop - 1}px)`;
export const desktop = `@media (min-width: ${media.desktop}px)`;

/*
const fontFamilies = {
  SFProRoundedBlack: 'sf_pro_roundedblack',
  SFProRoundedRegular: 'sf_pro_roundedregular',
  SFProRoundedSemibold: 'sf_pro_roundedsemibold',
  Manrope: 'Manrope',
};
*/
